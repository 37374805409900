<template>
  <div id="ar-list">
    <v-card>
      <v-card-title>売掛一覧</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="customer_id"
            placeholder="取引先ID"
            :items="customerIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="計上日From"
                dense
                hide-details
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="計上日To"
                dense
                outlined
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="contracts"
        class="class-on-data-table elevation-1"
        :headers="headers"
        :items="accountReceivableList"
        :search="searchQuery"
      >
        <template v-slot:[`item.cust_abbr`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                :style="'text-decoration: underline; color:#666666' "
                v-on="on"
              >{{ item.cust_abbr }}</a>
            </template>
            <span>{{ item.cust_id }}</span><br>
            <span>{{ item.cust_name }}</span><br>
            <span>{{ item.cust_name_eng }}</span><br>
          </v-tooltip>
        </template>
        <template v-slot:[`item.ar_amount`]="{ item }">
          <label>{{
            item.ar_amount ? item.ar_amount.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0'
          }}</label>
        </template>
        <template v-slot:[`item.depo_applied_amount`]="{ item }">
          <label>{{
            item.depo_applied_amount ? item.depo_applied_amount.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0'
          }}</label>
        </template>

        <template v-slot:[`item.remain_amount`]="{ item }">
          <label>{{
            item.remain_amount ? item.remain_amount.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0'
          }}</label>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item.depo_id)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
          </div>
        </template>
        <template v-slot:[`body.append`]>
          <tr v-if="product_id">
            <td
              class="font-weight-bold"
              :colspan="5"
              style="background: #fafafa"
            >
              <label>{{ product_id }}の合計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ sumWgt }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="3"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  mdiPencil,
  mdiDelete,
  mdiFileSendOutline,
  mdiFileCheckOutline,
  mdiExportVariant,
  mdiFilePdfBox,
  mdiCalendar,
  mdiMinus,
  mdiPlus
} from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'

/* eslint-disable */
export default {
  components: {

  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiFileSendOutline,
      mdiFileCheckOutline,
      mdiCalendar,
      mdiFilePdfBox,
      mdiMinus,
      mdiPlus,
    },
    dateEndMenu: '',
    dateStartMenu: '',

    contract_status: '',
    contractStatuses: [
      { text: '仮契約', value: '1' },
      { text: '契約済', value: '2' },
      { text: '出荷可', value: '3' },
      { text: '出荷済', value: '4' },
      { text: '取消', value: '9' },
    ],
    searchBar: {},
    searchQuery: '',
    pellet_id: '',
    contract_id: '',
    customer_id: '',
    startDate: '',
    preorder_id:'',
    auction_id:'',
    endDate: '',
    isExpand: false,
    OperationDisabled: true,
    headers: [
      {
        text: 'ID.',
        value: 'ar_id',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '20%',
        fixed: true,
      },
      {
        text: '計上日',
        value: 'ar_dd',
        align: 'left',
        width: '15%',
        fixed: true,
      },
      {
        text: '通貨',
        value: 'ccy_code',
        align: 'right',
        width: '15%',
        fixed: true,
      },

      {
        text: '売掛金',
        value: 'ar_amount',
        align: 'right',
        width: '15%',
        fixed: true,
      },


      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '10%',
        fixed: true,
      },
    ],
    product_id:'',
  }),

  computed: {

    ...mapState('customerStore', ['customerIds']),
    ...mapState('accountReceivableStore', ['accountReceivableList']),



  },
  watch: {



    customer_id(newValue) {
      this.changeRouteQuery('cust_id', newValue)
      this.loadData()
    },


    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)

        this.loadData()

    },
  },
  created() {
    console.log("created")
    this.loadQuery()
    this.changeRouteQuery()

     this.loadData()
  },

  methods: {

    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('accountReceivableStore', ['loadAccountReceivables']),

    ...mapMutations('app', ['setOverlayStatus']),

    previewItem(item)
    {
      console.log('view', item)
      this.$router.push({
        name: 'quotation-preivew',
        params: {
          id: item.quot_id,
        },
      })
    },

    openOperationDialog() {
      this.dialog = true
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCustomerIds(),
        this.loadAccountReceivables(this.$route.query),
      ]).then(() => {
        console.log('ar:',this.accountReceivableList, this.customerIds)
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })

    },
    editItem(contractId) {

      this.$router.push({ name: 'contract-pellet-edit', params: { id: contractId, query: this.$route.query } })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({ path: this.$route.path, query })
    },
    loadQuery() {
      this.contract_id = this.$route.query.contract_id ?? ''

      this.customer_id = parseInt(this.$route.query.cust_id) ?? ''
      this.contract_status = this.$route.query.contract_status ?? ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },
    downloadCSV() {

      // eslint-disable-next-line no-useless-concat

    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
tr {
  height: 50px;
  font-size: 14px;
}
td {
  border-bottom: thin solid rgba(94, 86, 105, 0.14);
  padding: 0px 16px;
}
</style>
